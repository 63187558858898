<template>
  <LayoutModalComponent
    v-if="hasData && hasSlug"
    :minHeight="pageToPreview !== 'newsletter'"
    :parentRoute="parentRoute"
    :backgroundColor="data?.data[0]?.attributes?.background_color"
  >
    <PagesNewsSlugComponent
      :pageData="data"
      :parentRoute="parentRoute"
      v-if="pageToPreview === 'news-slug'"
    />
    <PagesEventsSlugComponent
      :pageData="data"
      :parentRoute="parentRoute"
      v-else-if="pageToPreview === 'events-slug'"
    />
    <PagesFocusWeekSlugComponent
      :pageData="data"
      :parentRoute="parentRoute"
      v-else-if="pageToPreview === 'focus-week-slug'"
    />
    <PagesParticipantSlugComponent
      :pageData="data"
      :parentRoute="parentRoute"
      v-else-if="pageToPreview === 'participants-slug'"
    />
    <PagesPublicationsSlugComponent
      :pageData="data"
      :parentRoute="parentRoute"
      v-else-if="pageToPreview === 'publications-slug'"
    />
    <PagesNewsletterComponent
      :pageData="data"
      :parentRoute="parentRoute"
      v-else-if="pageToPreview === 'newsletter'"
    />
    <PagesClustersSlugComponent
      :pageData="data"
      :parentRoute="parentRoute"
      v-else-if="pageToPreview === 'clusters-slug'"
    />
    <PagesCitiesSlugComponent
      :pageData="data"
      :parentRoute="parentRoute"
      v-else-if="pageToPreview === 'cities-slug'"
    />
    <PagesVenuesSlugComponent
      :pageData="data"
      :parentRoute="parentRoute"
      v-else-if="pageToPreview === 'venues-slug'"
    />
    <PagesNewsletterComponent
      v-else-if="pageToPreview === 'index-newsletter'"
    />
  </LayoutModalComponent>

  <LayoutDataPendingComponent
    v-if="query && hasSlug && pending"
  />
</template>

<script lang="ts" setup>
import { omit } from 'lodash-es'
const route = useRoute()

const slug = computed(() => route?.query?.slug || route.params?.slug)

const hasSlug = computed(() => {
  if (page?.value === 'newsletter') {
    return true
  } else {
    return slug.value !== undefined && slug.value?.length > 0
  }
})

const page = computed(() => {
  return route.query?.page || route?.name?.split('___')[0]
})

const pageToPreview = ref(null)

const hasData = computed(() => {
  return data.value?.data !== undefined && data.value?.data?.length > 0 || data.value?.data?.id
})

const query = computed(() => {
  const publicationState = route?.query?.publicationState || 'live'
  switch (page.value) {
    case 'newsletter':
      return queryNewsletterPage()
    case 'news-slug':
      return queryNewsEntryBySlug({ slug: slug.value, locale: baseI18nGetActiveIso(), publicationState: publicationState })
    case 'events-slug':
      return queryEventEntryBySlug({ slug: slug.value, locale: baseI18nGetActiveIso(), publicationState: publicationState })
    case 'participants-slug':
      return queryParticipantEntryBySlug({ slug: slug.value, locale: baseI18nGetActiveIso(), publicationState: publicationState })
    case 'publications-slug':
      return queryPublicationsEntryBySlug({ slug: slug.value, locale: baseI18nGetActiveIso(), publicationState: publicationState })
    case 'newsletter':
      return queryNewsletterPage({ locale: baseI18nGetActiveIso(), publicationState: publicationState })
    case 'clusters-slug':
      return queryClusterEntryBySlug({ slug: slug.value, locale: baseI18nGetActiveIso(), publicationState: publicationState })
    case 'cities-slug':
      return queryCityEntryBySlug({ slug: slug.value, locale: baseI18nGetActiveIso(), publicationState: publicationState })
    case 'venues-slug':
      return queryVenueEntryBySlug({ slug: slug.value, locale: baseI18nGetActiveIso(), publicationState: publicationState })
    case 'focus-week-slug':
      return queryFocusWeekEntryBySlug({ slug: slug.value, locale: baseI18nGetActiveIso(), publicationState: publicationState })
  }
})

const { data, error, execute, pending, clear } = await useAsyncData(() => {
  return $fetch('/api/strapi-rest',
    { query: { path: query.value } }
  )
}, { immediate: false })

watch(slug, (value) => {
  if (hasSlug.value && query.value !== undefined) {
    execute()
  } else {
    clear()
  }
}, { immediate: true })

watch(pending, (value) => {
  if (value === false && hasData.value) {
    pageToPreview.value = page.value
  }
  else if (value === false && !hasData.value){
    pageToPreview.value = null
  }
}, { immediate: true })

const parentRoute = computed(() => {
  if (route?.matched?.length > 1) {
    return {
      path: route.matched[0].path,
      query: omit(route.query, ['page', 'slug'])
    }
  } else {
    return {
      path: route.path,
      query: omit(route.query, ['page', 'slug'])
    }
  }
})
</script>
